import { createRouter, createWebHistory } from "vue-router"; 
import useAuthUser from "@/lib/SupaAuth";
import landingPage from '@/components/landingPage.vue';

const routes = [
    {
        path: '/',
        name: 'landingPage',
        component: landingPage,
        
    },

    {
        path: '/signup',
        name: 'signup',
        component: () => import('@/components/signupFile.vue'), //lazy loading
     
    },

    {
        path: '/EmailConfirmation',
        name: 'EmailConfirmation',
        component: () => import('@/components/emailConfig.vue'), //lazy loading
     
    },
    
    {
        path: "/login",
        name: "login",
        component: () => import("@/components/loginFile.vue"),
    },
    
    {   
        path: "/forgotPassword",
        name: "forgotPassword",
        component: () => import("@/components/forgotPassword.vue"),
    },

    {   
        path: "/profile",
        name: "profile",
        component: () => import("@/components/profileFile.vue"),
        meta: {
            requiresAuth: true,
        },
    },
        

]

const router = createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
      },
});

router.beforeEach((to) => {
    const { isLoggedIn } = useAuthUser();
    if (
      !isLoggedIn() &&
      to.meta.requiresAuth &&
      !Object.keys(to.query).includes("fromEmail")
    ) {
      return { name: "Login" };
    }
  });


export default router
