 <template>
  <div id='landingBG' class="w-dvw h-dvh grid justify-center pt-20">
  

    <div class=" rounded-lg text-white bg-beige h-[180px] md:h-[18vh] w-[37vh] md:w-[45vh] p-5 shadow-black shadow-lg"> 
     <p class="text-brown font-bold text-lg">Create an account to get early access <br>
      </p>
      <button class="text-brown border-brown border-dotted hover:border-solid border-2 hover:bg-warmGeel transition duration-300 p-2 rounded-lg my-3"><router-link to="/signup">Create Account</router-link></button>
     
    </div>
  </div>
</template>

<script setup>

import { onMounted } from 'vue';


onMounted(() => {
    console.log('onMounted');
})

</script>

