import { createClient } from "@supabase/supabase-js";

// these can come from an environment variable if desired
// not required however as they are 100% exposed on the client side anyway 
// and that's ok, Supabase expects this (security is provided by Row Level Security)
const supabaseUrl = "https://ajwilfiuykmwxjxfbasl.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFqd2lsZml1eWttd3hqeGZiYXNsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUzNjg1NTEsImV4cCI6MjA0MDk0NDU1MX0.hpRVXuN3T1l_tMq1gqZgm2-xoV0Pth_A3EZfCJ9cLSw";

// setup client
const supabase = createClient(supabaseUrl, supabaseKey);

// expose supabase client
export default function useSupabase() {
  return { supabase };
}