import { ref } from 'vue';
import useSupabase from './useSupaBase';

export default function useAuthUser() {
  const { supabase } = useSupabase();
  const user = ref(null); // `user` state using ref

/**
/**
 * Login with email and password (using signInWithPassword)
 */
const login = async ({ email, password }) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email, 
    password,
  });
  if (error) throw new Error(`Login failed: ${error.message}`);
  return data.user;
};

/**
 * Login with Google, GitHub, etc. (using signInWithOAuth)
 */
const loginWithSocialProvider = async (provider) => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider, // provider should be a string like 'google', 'github', etc.
  });
  if (error) throw new Error(`Social login failed: ${error.message}`);
  return data.user;
};
  

/**
   * Logout
   */
  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    user.value = null; // clear the user state
  };

  /**
   * Check if the user is logged in or not
   */
  const isLoggedIn = () => {
    return !!user.value; // return true if the user is not null
  };

  /**
   * Register
   */
  const register = async ({ email, password, ...meta }) => {
    const { user: registeredUser, error } = await supabase.auth.signUp(
      { email, password },
      {
        data: meta,
        redirectTo: `${window.location.origin}/me?fromEmail=registrationConfirmation`,
      }
    );
    if (error) throw error;
    user.value = registeredUser; // update the user state
    return registeredUser;
  };

  /**
   * Update user email, password, or meta data
   */
  const update = async (data) => {
    const { user: updatedUser, error } = await supabase.auth.update(data);
    if (error) throw error;
    user.value = updatedUser; // update the user state
    return updatedUser;
  };

  /**
   * Send user an email to reset their password
   */
  const sendPasswordResetEmail = async (email) => {
    const { error } = await supabase.auth.api.resetPasswordForEmail(email);
    if (error) throw error;
  };

  return {
    user,
    login,
    loginWithSocialProvider,
    isLoggedIn,
    logout,
    register,
    update,
    sendPasswordResetEmail,
  };
}